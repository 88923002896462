<template>
  <Chart ref="chart" :options="chartOptions" :constructor-type="'stockChart'"></Chart>
</template>

<script setup>
import { Chart } from 'highcharts-vue';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import compositionUtils from '@/compositionUtils';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';

stockInit(Highcharts);

const props = defineProps([
  'refresh',
  'redraw',
  'width'
]);

const http = inject('http');
// Store
const store = useStore();

const chart = ref(null);
const mode = computed(() => store.state.mode);
const market = computed(() => store.state.market);
const baseMarket = computed(() => store.state.baseMarkets.filter(b => b.isSelected)[0]);
const user = computed(() => store.state.user);
const chartOptions = computed(() => {
  return {
    yAxis: [{
      height: '60%',
      lineWidth: 2,
      resize: { enabled: true },
      gridLineColor: getColor('line'),
      labels: {
        align: 'left',
        x: 4,
        style: { color: getColor('label') },
        format: '{value:.8f}',
        formatter: function() {
          return numToPlain(this.value);
        }
      },
      lineColor: getColor('line'),
      minorGridLineColor: getColor('line'),
      tickColor: getColor('line')
    }, {
      labels: {
        align: 'left',
        x: 4,
        style: { color: getColor('label') }
      },
      title: null,
      top: '60%',
      height: '40%',
      offset: 0,
      lineWidth: 2,
      gridLineColor: getColor('line'),
      lineColor: getColor('line'),
      minorGridLineColor: getColor('line'),
      tickColor: getColor('line')
    }],
    xAxis: {
      gridLineColor: getColor('line'),
      labels: { style: { color: mode.value === 'light' ? '#303030' : '#d6d6d6' } },
      lineColor: getColor('line'),
      minorGridLineColor: getColor('line'),
      tickColor: getColor('line')
    },
    credits: {
      enabled: false
    },
    exporting: { enabled: false },
    chart: {
      backgroundColor: mode.value === 'dark' ? '#1f2937' : 'transparent',
      plotBorderColor: getColor('border')
    },
    plotOptions: {
      candlestick: {
        colorByPoint: false,
        color: '#c43636',
        lineColor: '#c43636',
        upColor: '#98b92e',
        upLineColor: '#98b92e',
        states: { hover: { enabled: false } }
      }
    },
    scrollbar: { enabled: false },
    rangeSelector: {
      inputEnabled: false,
      selected: selected.value,
      verticalAlign: 'bottom',
      buttons: [{
        type: 'day',
        count: 1,
        text: '24H'
      }, {
        type: 'all',
        text: 'All'
      }]
    },
    tooltip: {
      split: false,
      positioner: function() {
        return {
          x: 10,
          y: 10
        };
      },
      backgroundColor: '#FFFFFF',
      borderWidth: 0,
      shadow: false,
      padding: 0,
      useHTML: true,
      formatter: function() {
        let html =
          '<span class="tooltip-span">Time: ' +
          moment.tz(this.point.x, 'GMT').format('YYYY-MM-DD HH:mm') +
          '</span>';
        if (typeof this.point.open !== 'undefined') {
          html +=
            '<span class="tooltip-span">Open: ' +
            numToPlain(this.point.open) +
            '</span>' +
            '<span class="tooltip-span">High: ' +
            numToPlain(this.point.high) +
            '</span>' +
            '<span class="tooltip-span">Low: ' +
            numToPlain(this.point.low) +
            '</span>' +
            '<span class="tooltip-span">Close: ' +
            numToPlain(this.point.close) +
            '</span>';
        } else {
          html +=
            '<span class="tooltip-span ml-2">Volume: ' + numToPlain(this.point.y) + '</span>';
        }
        return html;
      },
      crosshairs: [true, true]
    },
    series: [
      {
        type: 'candlestick',
        data: ohlc.value,
        yAxis: 0
      },
      {
        type: 'column',
        data: volumeData.value,
        yAxis: 1
      }
    ]
  };
});

const ohlc = ref([]);
const volumeData = ref([]);
const selected = ref(1);

const { numToPlain, baseMarketName } = compositionUtils();

watch(() => user.value, () => {
  fetchMarketChartData();
});

watch(() => market.value, () => {
  fetchMarketChartData();
});

watch(() => props.refresh, () => {
  fetchMarketChartData();
});

watch(() => props.redraw, () => {
  if (chart.value) {
    chart.value.chart.setSize(props.width - 40);
    chart.value.chart.reflow();
  }
});

const getColor = (type) => {
  let color = '';
  switch (type) {
    case 'line':
      color = mode.value === 'light' ? '#e0e0e0' : '#18202a';
      break;
    case 'border':
      color = mode.value === 'light' ? '#e0e0e0' : '#18202a';
      break;
    case 'bg':
      color = mode.value === 'light' ? '#FFFFFF' : '#2f385f3b';
      break;
    default:
      color = mode.value === 'light' ? '#303030' : '#d6d6d6';
      break;
  }
  return color;
};

const fetchMarketChartData = () => {
  if (chart.value) {
    chart.value.chart.showLoading();
  }
  volumeData.value = [];
  ohlc.value = [];
  const params = {
    Call: 'TradingInfo',
    AltCoinID: market.value.AltCoinID,
    Output: 'json',
    MerchantID: user.value ? user.value.ID : 1,
    APIKey: user.value ? user.value.APIKey : '_',
    BaseMarket: baseMarketName(baseMarket.value.name)
  };
  const endPoint = 'https://artemis.cointopay.com/trading/';
  http.get(endPoint, {
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then(response => {
    if (chart.value) {
      chart.value.chart.hideLoading();
    }
    let data = response.data.data;
    // sort data on date
    data = data.sort(function(a, b) {
      return a[0] - b[0];
    });
    data.forEach(element => {
      if (element.date !== undefined) {
        ohlc.value.push([
          element.date * 1000,
          element.open,
          element.high,
          element.low,
          element.close
        ]);
        volumeData.value.push([element.date * 1000, element.volume]);
      } else {
        ohlc.value.push([
          element[0] * 1000,
          element[1],
          element[2],
          element[3],
          element[4]
        ]);
        volumeData.value.push([element[0] * 1000, element[5]]);
      }
    });
  }).catch(error => {
    if (chart.value) {
      chart.value.chart.hideLoading();
    }
    console.log(error);
  });
};

onBeforeMount(() => {
  fetchMarketChartData();
});

</script>

<style scoped lang="scss">
::v-deep(.highcharts-tooltip) {
  >span {
    padding: 8px 16px;

    @media (max-width: 767px) {
      padding: 8px;
    }
  }

  .tooltip-span {
    display: inline-block;
    color: #303030;
    padding: 0 16px;

    @media (max-width: 767px) {
      display: block;
      font-size: 11px;
      padding: 0;
    }
  }
}
</style>
