<template>
  <template v-if="chartOptions">
    <highcharts ref="chart" :options="chartOptions" type="chart"></highcharts>
  </template>
</template>

<script setup>
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import compositionUtils from '@/compositionUtils';

const props = defineProps([
  'refresh',
  'redraw',
  'width'
]);

const http = inject('http');
// Store
const store = useStore();
const { numToPlain, baseMarketName } = compositionUtils();

const mode = computed(() => store.state.mode);
const market = computed(() => store.state.market);
const user = computed(() => store.state.user);
const baseMarket = computed(() => store.state.baseMarkets.filter(b => b.isSelected)[0]);
const title = ref('');
const isProcessing = ref(false);

const chart = ref(null);
const data = ref({});

watch(() => user.value, () => {
  fetchDepthChartData();
});

watch(() => market.value, () => {
  fetchDepthChartData();
});

watch(() => props.refresh, () => {
  fetchDepthChartData();
});

const getColor = (type) => {
  let color = '';
  switch (type) {
    case 'line':
      color = mode.value === 'light' ? '#d0d0d0' : '#333358';
      break;
    case 'bg':
      color = mode.value === 'light' ? '#FFFFFF' : '#1f2937';
      break;
    case 'border':
      color = mode.value === 'light' ? '#808080' : '#333358';
      break;
    case 'plotLine':
      color = mode.value === 'light' ? '#ffffff' : '#888888';
      break;
    default:
      color = mode.value === 'light' ? '#505050' : '#d6d6d6';
      break;
  }
  return color;
};

const fetchDepthChartData = () => {
  title.value = `${baseMarket.value.name}-${market.value.ShortName} Market Depth`;
  isProcessing.value = true;
  const params = {
    Call: 'DepthInfo',
    AltCoinID: market.value.AltCoinID,
    Output: 'json',
    MerchantID: user.value ? user.value.ID : 1,
    APIKey: user.value ? user.value.APIKey : '_',
    BaseMarket: baseMarketName(baseMarket.value.name)
  };
  const endPoint = 'https://artemis.cointopay.com/trading/';
  http.get(endPoint, {
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then(response => {
    isProcessing.value = false;
    data.value = response.data.data;
    data.value = {
      bids: data.value.bids,
      asks: data.value.asks.sort(function(a, b) {
        return a[0] - b[0];
      }).sort(function(a, b) {
        return b[1] - a[1];
      })
    };
  }).catch(error => {
    isProcessing.value = false;
    console.log(error);
  });
};

// Draw depth chart
const chartOptions = computed(() => {
  return {
    chart: {
      backgroundColor: 'transparent',
      plotBorderColor: getColor('border'),
      type: 'area',
      zoomType: 'xy',
      style: {
        fontFamily: '\'Ubuntu\', sans-serif',
        fontSize: '14px'
      }
    },
    title: {
      text: title.value,
      style: {
        color: getColor('label'),
        textTransform: 'uppercase',
        fontSize: '20px'
      }
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    xAxis: {
      minPadding: 0,
      maxPadding: 0,
      gridLineColor: getColor('line'),
      lineColor: getColor('line'),
      minorGridLineColor: '#505053',
      tickColor: getColor('line'),
      labels: {
        style: {
          color: getColor('label'),
          fontSize: '13px'
        },
        formatter: function() {
          return numToPlain(this.value);
        }
      },
      title: {
        text: 'Price',
        style: {
          color: getColor('label'),
          textTransform: 'uppercase',
          fontSize: '14px'
        }
      },
      plotLines: [
        {
          color: getColor('line'),
          value: data.value.bids && data.value.bids.length > 0 ? data.value.bids[data.value.bids.length - 1][0] : '',
          width: 1,
          label: {
            text: 'Depth',
            rotation: 90,
            style: {
              color: getColor('label')
            }
          }
        }
      ]
    },
    yAxis: [{
      lineWidth: 1,
      gridLineWidth: 1,
      title: null,
      tickWidth: 1,
      tickLength: 5,
      tickPosition: 'inside',
      labels: {
        style: {
          color: getColor('label'),
          fontSize: '13px'
        },
        align: 'left',
        x: 8
      },
      gridLineColor: getColor('line'),
      lineColor: getColor('line'),
      minorGridLineColor: '#505053',
      tickColor: getColor('line')
    }, {
      opposite: true,
      linkedTo: 0,
      lineWidth: 1,
      gridLineWidth: 0,
      title: null,
      tickWidth: 1,
      tickLength: 5,
      tickPosition: 'inside',
      labels: {
        style: {
          color: getColor('label'),
          fontSize: '13px'
        },
        align: 'right',
        x: -8
      },
      gridLineColor: getColor('line'),
      lineColor: getColor('line'),
      minorGridLineColor: '#505053',
      tickColor: getColor('line')
    }],
    legend: { enabled: false },
    plotOptions: {
      area: {
        fillOpacity: 0.2,
        lineWidth: 1,
        step: 'center'
      }
    },
    tooltip: {
      formatter: function() {
        return (
          'Price <b>' +
          numToPlain(this.x) +
          '</b> <br/>' +
          this.series.name +
          ' <b>' +
          numToPlain(this.y) +
          '</b>'
        );
      },
      backgroundColor: getColor('bg'),
      style: {
        color: mode.value === 'light' ? '#303030' : '#d6d6d6',
        fontSize: '13px'
      }
    },
    series: [
      {
        name: 'Bids',
        data: data.value.bids,
        color: '#98b92e'
      },
      {
        name: 'Asks',
        data: data.value.asks,
        color: '#c43636'
      }
    ]
  };
});

watch(() => props.redraw, () => {
  if (chart.value) {
    chart.value.chart.setSize(props.width - 40);
    chart.value.chart.reflow();
  }
});

onBeforeMount(() => {
  fetchDepthChartData();
});

</script>

<style scoped></style>
